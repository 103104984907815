<template>
  <div class="home">
    <img src="@/assets/images/bg.png" class="img-bg" />
    <div class="time-border">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="time-border2">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="time-border3">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
      <div class="time-wp time1-wp" :style="{top: top}">
        <span class="text">{{day}}</span>
      </div>
      <div class="time-wp time2-wp" :style="{top: top}">
        <span class="text">{{hour}}</span>
      </div>
      <div class="time-wp time3-wp" :style="{top: top}">
        <span class="text">{{minute}}</span>
      </div>
      <div class="time-wp time4-wp" :style="{top: top}">
        <span class="text">{{second}}</span>
      </div>
  </div>
</template>

<script>
import {startAt} from '@/data/date.json';

export default {
  name: 'IndexPage',
  props: {},
  data: function () {
    return {
      interval: null,
      day: "0",
      hour: "00",
      minute: "00",
      second: "00",
      top: "43.2%",
    }
  },
  created() {
    this.interval = setInterval(this.calcDate, 1000);
  },
  mounted() {
    this.resize();
    window.onresize = this.resize;
  },
  unmounted() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    calcDate: function() {
      let startTimestamp = parseInt(Date.parse(startAt) / 1000);
      let now = parseInt(Date.now() / 1000);
      let diffSec = 0;
      if (startTimestamp > now) {
        diffSec = startTimestamp - now;
      } else {
        if (this.interval) {
          clearInterval();
        }
      }

      this.day = parseInt(diffSec / 86400);
      diffSec = diffSec % 86400;
      let hour = parseInt(diffSec / 3600);
      if (hour < 10) {
        this.hour = "0" + hour
      } else {
        this.hour = hour;
      }
      diffSec = diffSec % 3600;
      let minute = parseInt(diffSec / 60);
      if (minute < 10) {
        this.minute = "0" + minute
      } else {
        this.minute = minute;
      }
      let second = diffSec % 60;
      if (second < 10) {
        this.second = "0" + second
      } else {
        this.second = second;
      }
    },
    resize: function() {
      let height = document.documentElement.clientHeight;
      let width = document.documentElement.clientWidth;
      if (height < 600) {
        if (width < 960) {
          this.top = "43.6%";
        } else if (width < 1200) {
          this.top = "43.2%";
        } else if (width < 1366) {
          this.top = "42%";
        } else if (width < 1440) {
          this.top = "41.6%";
        } else if (width < 1680) {
          this.top = "41.6%";
        } else {
          this.top = "40.8%";
        }
      } else if (height < 740) {
        if (width < 960) {
          this.top = "44.6%";
        } else if (width < 1200) {
          this.top = "44.2%";
        } else if (width < 1366) {
          this.top = "43%";
        } else if (width < 1440) {
          this.top = "42.6%";
        } else if (width < 1680) {
          this.top = "42.6%";
        } else {
          this.top = "41.8%";
        }
      } else {
        if (width < 960) {
          this.top = "45.2%";
        } else if (width < 1200) {
          this.top = "44.8%";
        } else if (width < 1366) {
          this.top = "43.6%";
        } else if (width < 1440) {
          this.top = "43.2%";
        } else if (width < 1680) {
          this.top = "43.2%";
        } else {
          this.top = "42.4%";
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .home {
    width: 100vw;
    height: 100vh;

    .img-bg {
      width: 100%;
      height: 100%;
      min-width: 960px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 0;
    }

    .time-border {
      width: 54.8%;
      height: 30%;
      border-radius: 2rem;
      z-index: 1;
      position: absolute;
      left: 23.3%;
      top: 34%;
      overflow: hidden;

      span {
        position: absolute;
        display: block;
      }

      span:nth-child(1) {
        top: 0;
        left: 0;
        width: 100%;
        height: 6px;
        background: linear-gradient(90deg, transparent, #F403F0);
        animation: animate1 2s linear infinite;
      }
      span:nth-child(2) {
        top: -100%;
        right: 0;
        width: 6px;
        height: 100%;
        background: linear-gradient(180deg, transparent, #F403F0);
        animation: animate2 2s linear infinite;
        animation-delay: .5s;
      }
      span:nth-child(3) {
        bottom: 0;
        right: 0;
        width: 100%;
        height: 6px;
        background: linear-gradient(270deg, transparent, #F403F0);
        animation: animate3 2s linear infinite;
        animation-delay: 1s;
      }
      span:nth-child(4) {
        bottom: -100%;
        left: 0;
        width: 6px;
        height: 100%;
        background: linear-gradient(360deg, transparent, #F403F0);
        animation: animate4 2s linear infinite;
        animation-delay: 1.5s;
      }
    }

    .time-border:nth-child(1) {
      filter: hue-rotate(270deg);
    }

    .time-border2 {
      width: 52%;
      height: 26.6%;
      border-radius: 2rem;
      z-index: 1;
      position: absolute;
      left: 24.6%;
      top: 35.5%;
      overflow: hidden;

      span {
        position: absolute;
        display: block;
      }

      span:nth-child(1) {
        top: 0;
        left: 0;
        width: 100%;
        height: 6px;
        background: linear-gradient(90deg, transparent, #f4dc03);
        animation: animate1 2s linear infinite;
      }
      span:nth-child(2) {
        top: -100%;
        right: 0;
        width: 6px;
        height: 100%;
        background: linear-gradient(180deg, transparent, #F4DC03);
        animation: animate2 2s linear infinite;
        animation-delay: .5s;
      }
      span:nth-child(3) {
        bottom: 0;
        right: 0;
        width: 100%;
        height: 6px;
        background: linear-gradient(270deg, transparent, #F4DC03);
        animation: animate3 2s linear infinite;
        animation-delay: 1s;
      }
      span:nth-child(4) {
        bottom: -100%;
        left: 0;
        width: 6px;
        height: 100%;
        background: linear-gradient(360deg, transparent, #F4DC03);
        animation: animate4 2s linear infinite;
        animation-delay: 1.5s;
      }
    }

    .time-border2:nth-child(1) {
      filter: hue-rotate(270deg);
    }

    .time-border3 {
      width: 48.8%;
      height: 22.6%;
      border-radius: 2rem;
      z-index: 1;
      position: absolute;
      left: 26.2%;
      top: 37.2%;
      overflow: hidden;

      span {
        position: absolute;
        display: block;
      }

      span:nth-child(1) {
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(90deg, transparent, #03e9f4);
        animation: animate1 2s linear infinite;
      }
      span:nth-child(2) {
        top: -100%;
        right: 0;
        width: 4px;
        height: 100%;
        background: linear-gradient(180deg, transparent, #03e9f4);
        animation: animate2 2s linear infinite;
        animation-delay: .5s;
      }
      span:nth-child(3) {
        bottom: 0;
        right: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(270deg, transparent, #03e9f4);
        animation: animate3 2s linear infinite;
        animation-delay: 1s;
      }
      span:nth-child(4) {
        bottom: -100%;
        left: 0;
        width: 4px;
        height: 100%;
        background: linear-gradient(360deg, transparent, #03e9f4);
        animation: animate4 2s linear infinite;
        animation-delay: 1.5s;
      }
    }

    .time-border3:nth-child(1) {
      filter: hue-rotate(270deg);
    }

    .time-wp {
      z-index: 1;
      width: 6.3%;
      min-width: 60px;
      height: 11%;
      top: 43.2%;
      position: absolute;
      text-align: center;

      .text {
        font-size: 4.4rem;
        color: #FFFFFF;
        font-weight: bold;
      }
    }

    .time1-wp {
      left: 30%;
    }

    .time2-wp {
      left: 41.9%;
    }

    .time3-wp {
      left: 52.9%;
    }

    .time4-wp {
      left: 63.9%;
    }

    @media (min-width:640px){
      .time-wp {
        top: 45.2%;
      }
      .time1-wp {
        left: 30.4%;
      }

      .time2-wp {
        left: 42.8%;
      }

      .time3-wp {
        left: 54.2%;
      }

      .time4-wp {
        left: 65.4%;
      }
    }
    @media (min-width:960px){
      .time-wp {
        top: 44.8%;
      }
      .time1-wp {
        left: 30%;
      }

      .time2-wp {
        left: 41.9%;
      }

      .time3-wp {
        left: 52.9%;
      }

      .time4-wp {
        left: 63.9%;
      }
    }
    @media (min-width:1200px){
      .time-wp {
        top: 44.1%;
      }
      .time1-wp {
        left: 30%;
      }

      .time2-wp {
        left: 41.9%;
      }

      .time3-wp {
        left: 52.9%;
      }

      .time4-wp {
        left: 63.9%;
      }
    }
    @media (min-width: 1366px) {
      .time-wp {
        top: 43.6%;
      }
      .time1-wp {
        left: 30%;
      }

      .time2-wp {
        left: 41.9%;
      }

      .time3-wp {
        left: 52.9%;
      }

      .time4-wp {
        left: 63.9%;
      }
    }
    @media (min-width: 1440px) {
      .time-wp {
        top: 43.2%;
      }
      .time1-wp {
        left: 30%;
      }

      .time2-wp {
        left: 42%;
      }

      .time3-wp {
        left: 53.1%;
      }

      .time4-wp {
        left: 64.2%;
      }
    }
    @media (min-width: 1680px) {
      .time-wp {
        top: 43.2%;
      }
      .time1-wp {
        left: 30%;
      }

      .time2-wp {
        left: 41.9%;
      }

      .time3-wp {
        left: 52.9%;
      }

      .time4-wp {
        left: 63.9%;
      }
    }
    @media (min-width: 1920px) {
      .time-wp {
        top: 42.4%;
      }
      .time1-wp {
        left: 30%;
      }

      .time2-wp {
        left: 41.9%;
      }

      .time3-wp {
        left: 52.9%;
      }

      .time4-wp {
        left: 63.9%;
      }
    }
  }

  @keyframes animate1 {
    0% {
      left: -100%
    }

    50%,100% {
      left: 100%
    }
  }

  @keyframes animate2 {
    0% {
      top: -100%
    }

    50%,100% {
      top: 100%
    }
  }

  @keyframes animate3 {
    0% {
      right: -100%
    }

    50%,100% {
      right: 100%
    }
  }

  @keyframes animate4 {
    0% {
      bottom: -100%
    }

    50%,100% {
      bottom: 100%
    }
  }
</style>
