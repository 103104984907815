<template>
  <index-page/>
</template>

<script>
import IndexPage from './pages/IndexPage.vue'

export default {
  name: 'App',
  components: {
    IndexPage
  }
}
</script>

<style>
html, body, div {
  padding: 0;
  margin: 0;
}
html {
  font-size: 16px;
  min-width: 960px;
}
body {
  min-width: 960px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow: hidden;
  min-width: 960px;
}
#app::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}
@media (min-width:640px){
  html{
    font-size: 12px;
  }
}
@media (min-width:960px){
  html{
    font-size: 14px;
  }
}
@media (min-width:1200px){
  html{
    font-size: 16px;
  }
}
@media (min-width: 1366px) {
  html{
    font-size: 18px;
  }
}
@media (min-width: 1440px) {
  html{
    font-size: 20px;
  }
}
@media (min-width: 1680px) {
  html{
    font-size: 22px;
  }
}
@media (min-width: 1920px) {
  html{
    font-size: 24px;
  }
}
</style>
